@keyframes marquee {
    0% {
      transform: translateX(10%);
    }
    100% {
      transform: translateX(-120%);
    }
  }
  
  .marquee {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 20s linear infinite;
  }
  
  .marquee-wrapper {
    overflow: hidden;
    width: 100%;
  }

  /* monitor.css */

/* Fullscreen styles */
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

/* Zoom styles */
.zoom-in {
  transform: scale(1.2);
}

.zoom-out {
  transform: scale(0.8);
}

/* Flex container */
.flex-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
